import Header from '../Header';
import React from 'react';

import DataProvider from '../DataProvider';
import UserProvider from '../UserProvider';
import NavBar from '../NavBar';
import List from '../List';
import Wrapper from '../Wrapper';

import styled from 'styled-components';

function App() {
  return (
    <UserProvider>
      <DataProvider>
        <div className='wrapper'>
          <Wrapper>
            <NavBar />
            {/* <Header /> */}
            <List />
          </Wrapper>
        </div>
      </DataProvider>
    </UserProvider>
  );
}

export default App;
