import React from 'react';

import { UserContext } from '../UserProvider';

export const DataContext = React.createContext();

const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

function DataProvider({ children }) {
  const [tasks, setTasks] = React.useState({
    tasks: {},
    columns: {
      'column-1': {
        id: 'column-1',
        title: 'All',
        taskIds: [],
      },
    },
    columnOrder: ['column-1'],
  });
  const [projectsList, setProjectsList] = React.useState([]);
  const [projects, setProjects] = React.useState(['DEFAULT']);
  const [projectTasksWereRequested, setProjectTasksWereRequested] =
    React.useState(true);
  const [projectTasksWereFetched, setProjectTasksWereFetched] =
    React.useState(false);
  const [projectsListWasRequested, setProjectsListWasRequested] =
    React.useState(true);
  const [projectsListWasFetched, setProjectsListWasFetched] =
    React.useState(false);

  const { token } = React.useContext(UserContext);

  const getProjectTitleFromTaskId = (taskId) => {
    for (const columnId in tasks.columns) {
      if (tasks.columns[columnId].taskIds.includes(taskId)) {
        return tasks.columns[columnId].title;
      }
    }
    return null;
  };

  React.useEffect(() => {
    async function getProjectTasks() {
      const URL = AUTH_ENDPOINT + `task?projects=${projects.join(',')}`;
      const HEADERS = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const request = new Request(URL, {
        method: 'GET',
        headers: HEADERS,
        timeout: 100000,
      });

      try {
        const response = await fetch(request);
        const json = await response.json();

        const processed = {};
        const columns = {};
        const columnOrder = [];

        const columnsList = [];

        Object.keys(json).forEach((project, index) => {
          columnsList.push(project);
          const ids = json[project].map((task) => {
            processed[task.ID] = {
              id: task.ID,
              project,
              content: task.TASK,
              status: task.STATUS,
              ...(task.DEADLINE && { deadline: task.DEADLINE }),
              ...(task.DEST_SK1 && { dest_sk1: task.DEST_SK1 }),
              ...(task.HABIT_RATING && { habit_rating: task.HABIT_RATING }),
            };
            return task.ID;
          });

          const columnId = `column-${index + 1}`;
          columns[columnId] = {
            id: columnId,
            title: project,
            taskIds: ids,
          };
          columnOrder.push(columnId);
        });

        const data = {
          tasks: processed,
          columns,
          columnOrder,
        };

        setTasks((oldData) => data);
        setProjects(columnsList);
        return json;
      } catch (error) {
        console.error('Error fetching project tasks:', error);
      }
    }
    if (projectTasksWereRequested && !projectTasksWereFetched && token) {
      getProjectTasks();
      setProjectTasksWereRequested(false);
      setProjectTasksWereFetched(true);
    }
  }, [projectTasksWereRequested, projectTasksWereFetched, projects, token]);

  React.useEffect(() => {
    async function getProjects() {
      const URL = AUTH_ENDPOINT + `project`;
      const HEADERS = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
      const request = new Request(URL, {
        method: 'GET',
        headers: HEADERS,
        timeout: 100000,
      });
      const response = await fetch(request);
      const json = await response.json();
      setProjectsList((oldData) => json);
      return json;
    }
    if (projectsListWasRequested && !projectsListWasFetched && token) {
      getProjects();
      setProjectsListWasRequested(false);
      setProjectsListWasFetched(true);
    }
  }, [projectsListWasRequested, projectsListWasFetched, token]);

  function fetchTasks() {
    setProjectTasksWereRequested(true);
    setProjectTasksWereFetched(false);
  }

  React.useEffect(() => {
    fetchTasks();
  }, [token]);

  const deleteTask = async (id) => {
    const project = getProjectTitleFromTaskId(id);
    const URL = AUTH_ENDPOINT + `task?project=${project}&id=${id}`;
    const HEADERS = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const request = new Request(URL, {
      method: 'DELETE',
      headers: HEADERS,
      timeout: 100000,
    });

    const result = fetch(request);
  };

  return (
    <DataContext.Provider
      value={{
        tasks,
        projects,
        setTasks,
        setProjects,
        fetchTasks,
        deleteTask,
        projectsList,
        setProjectsList,
        setProjectTasksWereRequested,
        setProjectTasksWereFetched,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export default DataProvider;
