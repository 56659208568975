import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import './styles2.css';

import { ulid } from 'ulid';

import { UserContext } from '../UserProvider';
import { DataContext } from '../DataProvider';

import { setHours, setMinutes, formatISO } from 'date-fns';

import { getColumnByProjectTitle } from '../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

const TaskDialog = ({ project }) => {
  // const AuthDialog = () => {
  const [task, setTask] = React.useState('');
  const [showDatepicker, setShowDatepicker] = React.useState(false);
  const [showRecurring, setShowRecurring] = React.useState(false);
  const [intervalType, setIntervalType] = React.useState('REGULAR');
  const { tasks, setTasks } = React.useContext(DataContext);
  const [startDate, setStartDate] = React.useState(
    setHours(setMinutes(new Date(), 59), 23)
  );
  const [estimatedTime, setEstimatedTime] = React.useState('');

  const handleClose = () => {
    setTask('');
    setShowDatepicker(false);
    setShowRecurring(false);
    setStartDate(setHours(setMinutes(new Date(), 59), 23));
    setEstimatedTime('');
  };

  const { token } = React.useContext(UserContext);
  const createTask = async () => {
    const URL = AUTH_ENDPOINT + `task`;
    const column = getColumnByProjectTitle(project, tasks);

    const taskUlid = ulid();

    const HEADERS = {
      'Content-Type': 'application/json',
      Authorization: token,
    };

    const formattedStartDate = formatISO(startDate);

    const BODY = JSON.stringify({
      project,
      content: task,
      id: taskUlid,
      ...(showDatepicker && { deadline: startDate }),
      ...(estimatedTime && { estimatedTime: parseInt(estimatedTime, 10) }),
    });
    const request = new Request(URL, {
      method: 'POST',
      headers: HEADERS,
      body: BODY,
      timeout: 100000,
    });
    const result = fetch(request);
    setTasks((oldTasks) => {
      const newTask = {
        id: taskUlid,
        content: task,
        project,
        status: 'INCOMPLETE',
      };
      const allTasks = {
        ...oldTasks.tasks,
        [taskUlid]: newTask,
      };
      const oldColumn = getColumnByProjectTitle(project, oldTasks);
      const oldColumnId = column['id'];
      const updatedTaskIds = [
        taskUlid,
        ...oldTasks.columns[oldColumnId].taskIds,
      ];
      const newColumn = {
        ...oldColumn,
        taskIds: updatedTaskIds,
      };
      const newData = {
        ...oldTasks,
        tasks: allTasks,
        columns: {
          ...tasks.columns,
          [newColumn.id]: newColumn,
        },
      };

      return newData;
    });
    setTask('');
  };

  function handleAddAttempt() {
    createTask();
  }

  return (
    <Dialog.Root onOpenChange={handleClose}>
      <Dialog.Trigger asChild>
        <span className='TaskButton violet'>{plusIcon}</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className='TaskDialogOverlay' />
        <Dialog.Content className='TaskDialogContent'>
          <Dialog.Title className='TaskDialogTitle'>Add Task</Dialog.Title>
          <fieldset className='TaskFieldset'>
            <label className='TaskLabel' htmlFor='task'>
              Task
            </label>
            <input
              value={task}
              onChange={(e) => setTask(e.target.value)}
              className='TaskInput'
              id='task'
            />
          </fieldset>
          {!showDatepicker && !showRecurring && (
            <fieldset className='TaskFieldset'>
              <label className='TaskLabel' htmlFor='addDeadline'>
                + Deadline
              </label>
              <input
                type='checkbox'
                id='addDeadline'
                className='CheckboxInput'
                checked={showDatepicker}
                onChange={() => setShowDatepicker(!showDatepicker)}
              />
            </fieldset>
          )}
          {showDatepicker && (
            <fieldset className='TaskFieldset'>
              <label className='TaskLabel' htmlFor='deadline'>
                Deadline
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                timeIntervals={5}
                dateFormat='MMMM d, yyyy h:mm aa'
              >
                <div
                  style={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: 'red',
                  }}
                ></div>
              </DatePicker>
              <button
                onClick={() => setShowDatepicker(false)}
                className='CloseButton'
              >
                {closeIcon}
              </button>
            </fieldset>
          )}
          {!showRecurring && (
            <fieldset className='TaskFieldset'>
              <label className='TaskLabel' htmlFor='addDeadline'>
                Recurring?
              </label>
              <input
                type='checkbox'
                id='addDeadline'
                className='CheckboxInput'
                checked={showRecurring}
                onChange={() => {
                  setShowRecurring(!showRecurring);
                  setShowDatepicker(false);
                }}
              />
            </fieldset>
          )}
          {showRecurring && (
            <fieldset className='TaskFieldset'>
              <label className='TaskLabel' htmlFor='intervalType'>
                Type
              </label>
              <select
                value={intervalType}
                onChange={(e) => setIntervalType(e.target.value)}
                className='TaskInput'
                id='intervalType'
              >
                <option value='REGULAR'>Recurring</option>
                <option value='CRON'>Cron</option>
              </select>
            </fieldset>
          )}
          <fieldset className='TaskFieldset'>
            <label className='TaskLabel' htmlFor='estimatedTime'>
              Est. Time
            </label>
            <input
              type='text'
              value={estimatedTime}
              onChange={(e) => setEstimatedTime(e.target.value)}
              className='TaskInput'
              id='estimatedTime'
              style={{
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-m"><text x="0" y="20" font-family="Arial" font-size="20">m</text></svg>')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
                paddingRight: '30px',
              }}
            />
          </fieldset>
          <div
            style={{
              display: 'flex',
              marginTop: 25,
              justifyContent: 'flex-end',
            }}
          >
            <Dialog.Close asChild>
              <button onClick={handleAddAttempt} className='TaskButton green'>
                Submit
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className='TaskIconButton' aria-label='Close'>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default TaskDialog;

const closeIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-x'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='m15 9-6 6' />
    <path d='m9 9 6 6' />
  </svg>
);

const plusIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-plus'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='M8 12h8' />
    <path d='M12 8v8' />
  </svg>
);
