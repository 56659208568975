import React from 'react';

// import { UserContext } from '../UserProvider';
import { DataContext } from '../DataProvider';
// import * as style from './Sequeriordan-calculator-hooks/src/components/SequenceComboBox/SequenceComboBox.module.cssnceComboBox.module.css';
import './style.css';

import * as style from './ProjectDropdown.module.css';

import * as Select from '@radix-ui/react-select';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@radix-ui/react-icons';

const ProjectDropdown = ({ selectFollowUp }) => {
  const { projectsList, projects } = React.useContext(DataContext);
  const [selectedProject, setSelectedProject] = React.useState('all');
  // const { token } = React.useContext(UserContext);

  function handleSelectValue(selection) {
    selectFollowUp(selection);
  }

  return (
    <Select.Root
      value={projects}
      onValueChange={(selection) => handleSelectValue(selection)}
    >
      <Select.Trigger className={style.SelectTrigger} aria-label='Sequences'>
        <Select.Value placeholder={projects} aria-label={projects}>
          {selectedProject}
        </Select.Value>
        <Select.Icon className={style.SelectIcon}>
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className={style.SelectContent}>
          <Select.ScrollUpButton className={style.SelectScrollButton}>
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className={style.SelectViewport}>
            <Select.Group>
              <Select.Label key='grouplabel' className={style.SelectLabel}>
                Project
              </Select.Label>
              {projectsList.map((item) => {
                return (
                  <SelectItem key={item} value={item}>
                    {item}
                  </SelectItem>
                );
              })}
            </Select.Group>
          </Select.Viewport>
          <Select.ScrollDownButton className={style.SelectScrollButton}>
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item className={'SelectItem'} {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className={style.SelectItemIndicator}>
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default ProjectDropdown;
