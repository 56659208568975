import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './styles.css';

import Column from '../Column';

import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';
import { updateOrder } from '../../utils';

const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

// const updateOrder = async (project, taskSequence, token) => {
//   const URL = AUTH_ENDPOINT + `task-sequence?project=${project}`;

//   const HEADERS = {
//     'Content-Type': 'application/json',
//     Authorization: token,
//   };
//   const BODY = JSON.stringify(taskSequence);
//   const request = new Request(URL, {
//     method: 'PUT',
//     headers: HEADERS,
//     body: BODY,
//     timeout: 100000,
//   });
//   fetch(request);
// };

export default function List() {
  const { tasks, setTasks } = React.useContext(DataContext);
  const { token } = React.useContext(UserContext);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    const start = tasks.columns[source.droppableId];
    const finish = tasks.columns[destination.droppableId];

    const startId = source.droppableId;
    const finishId = destination.droppableId;

    if (startId !== finishId) {
      const newStartTaskIds = Array.from(start.taskIds);
      newStartTaskIds.splice(source.index, 1);
      const newFinishTaskIds = Array.from(finish.taskIds);
      newFinishTaskIds.splice(destination.index, 0, result.draggableId);
      const newStartColumn = {
        ...start,
        taskIds: newStartTaskIds,
      };
      const newFinishColumn = {
        ...finish,
        taskIds: newFinishTaskIds,
      };
      const newData = {
        ...tasks,
        columns: {
          ...tasks.columns,
          [newStartColumn.id]: newStartColumn,
          [newFinishColumn.id]: newFinishColumn,
        },
        tasks: {
          ...tasks.tasks,
          [result.draggableId]: {
            ...tasks.tasks[result.draggableId],
            project: newFinishColumn.title,
          },
        },
      };
      const startProject = newStartColumn.title;
      const finishProject = newFinishColumn.title;
      updateOrder(startProject, newStartTaskIds, token);
      updateOrder(finishProject, newFinishTaskIds, token);
      setTasks(newData);
      return;
    }

    // if (startId !== finishId) {
    //   const newStartTaskIds = Array.from(start.taskIds);
    //   newStartTaskIds.splice(source.index, 1);
    //   const newFinishTaskIds = Array.from(finish.taskIds);
    //   newFinishTaskIds.splice(destination.index, 0, result.draggableId);
    //   const newStartColumn = {
    //     ...start,
    //     taskIds: newStartTaskIds,
    //   };
    //   const newFinishColumn = {
    //     ...finish,
    //     taskIds: newFinishTaskIds,
    //   };
    //   const newData = {
    //     ...tasks,
    //     columns: {
    //       ...tasks.columns,
    //       [newStartColumn.id]: newStartColumn,
    //       [newFinishColumn.id]: newFinishColumn,
    //     },
    //   };
    //   const startProject = newStartColumn.title;
    //   const finishProject = newFinishColumn.title;
    //   updateOrder(startProject, newStartTaskIds, token);
    //   updateOrder(finishProject, newFinishTaskIds, token);
    //   setTasks(newData);
    //   return;
    //   // newStartTaskIds.splice(destination.index, 0, result.draggableId);
    // }

    if (startId === finishId) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, result.draggableId);

      const newTaskTexts = newTaskIds.map((id) => {
        return `${id}: ${tasks.tasks[id].content}`;
      });
      console.log(newTaskTexts.join('\n'));

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newData = {
        ...tasks,
        columns: {
          ...tasks.columns,
          [newColumn.id]: newColumn,
        },
      };
      const project = newColumn.title;
      updateOrder(project, newTaskIds, token);
      setTasks(newData);
      return;
    }
  };

  // const data = tasks.sort((a, b) => a.order - b.order);

  return (
    <DragDropContext
      // onDragStart={}
      // onDragUpdate={}
      onDragEnd={onDragEnd}
    >
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        {tasks.columnOrder.map((columnId) => {
          const column = tasks.columns[columnId];
          const columnTasks = column.taskIds.map((taskId) => {
            return tasks.tasks[taskId];
          });

          return (
            <Column key={column.id} column={column} columnTasks={columnTasks} />
          );
        })}
      </div>
    </DragDropContext>
  );
}
