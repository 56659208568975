import React from 'react';

import styled from 'styled-components';

function Wrapper({ children }) {
  return <Div>{children}</Div>;
}

export default Wrapper;

const Div = styled.div``;
