import React from 'react';
export default function useCountdown(deadline) {
  const [countdown, setCountdown] = React.useState(0);

  React.useEffect(() => {
    const targetDate = new Date(deadline);

    const intervalId = window.setInterval(() => {
      const now = new Date();
      const timeLeft = targetDate - now;

      if (timeLeft <= 0) {
        setCountdown(0);
        window.clearInterval(intervalId);
      } else {
        setCountdown(timeLeft);
      }
    }, 67);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [deadline]);

  return [countdown];
}
