import React from 'react';

import styled from 'styled-components';

import useCountdown from '../../hooks/use-countdown.hook';

const ONE_HOUR_SECONDS = 60 * 60;
const ONE_SECOND_MILLIS = 1000;

function formatTimeLeft(timeLeft) {
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = String(Math.floor((timeLeft / (1000 * 60 * 60)) % 24)).padStart(
    2,
    '0'
  );

  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(
    2,
    '0'
  );
  const seconds = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, '0');
  const hundredths = String(Math.floor((timeLeft % 1000) / 10)).padStart(
    2,
    '0'
  );
  return (
    <>
      <Number>{days}</Number>
      <Unit>d:</Unit>
      <Number>{hours}</Number>
      <Unit>h:</Unit>
      <Number>{minutes}</Number>
      <Unit>m:</Unit>
      <Number>{seconds}</Number>
      <Unit>s:</Unit>
      <Number>{hundredths}</Number>
    </>
  );
}

function Countdown({ deadline }) {
  const [countdown] = useCountdown(deadline);

  const timeLeft = formatTimeLeft(countdown);
  var fontColor = 'white';
  if (countdown < ONE_HOUR_SECONDS * 12 * ONE_SECOND_MILLIS) {
    fontColor = 'yellow';
  }
  if (countdown < ONE_HOUR_SECONDS * 2 * ONE_SECOND_MILLIS) {
    fontColor = 'red';
  }
  return (
    <span>
      <span style={{ minWidth: '10px', display: 'inline-block' }} />
      <span style={{ backgroundColor: 'black', padding: '2px' }}>
        {timeLeft}
      </span>
    </span>
  );
}

const Number = styled.span`
  color: yellow;
  /* color: hsl(100, 50%, 50%); */
`;

const Unit = styled.span`
  color: white;
`;

export default Countdown;
