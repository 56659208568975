import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import './styles2.css';

import { UserContext } from '../UserProvider';
import { DataContext } from '../DataProvider';

const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

const ProjectDialog = () => {
  // const AuthDialog = () => {
  const [project, setProject] = React.useState('');

  const { setProjectsList } = React.useContext(DataContext);
  const { token } = React.useContext(UserContext);

  const createProject = async () => {
    const URL = AUTH_ENDPOINT + `project`;

    const HEADERS = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    const BODY = JSON.stringify({
      project,
    });
    const request = new Request(URL, {
      method: 'POST',
      headers: HEADERS,
      body: BODY,
      timeout: 100000,
    });
    const result = fetch(request);
  };

  function handleAddAttempt() {
    createProject();
    setProjectsList((oldValue) => [project, ...oldValue]);

    // if (!passwordIsValid) {
    //   window.alert('Password is not valid! Review the requirements!');
    //   return;
    // }
    // handleAddTask(email, password, firstname, lastname);
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <span className='ProjectButton violet'>+Project</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className='ProjectDialogOverlay' />
        <Dialog.Content className='ProjectDialogContent'>
          <Dialog.Title className='ProjectDialogTitle'>
            Add Project
          </Dialog.Title>
          <fieldset className='ProjectFieldset'>
            <label className='ProjectLabel' htmlFor='name'>
              Project
            </label>
            <input
              value={project}
              onChange={(e) => setProject(e.target.value)}
              className='ProjectInput'
              id='name'
            />
          </fieldset>
          <div
            style={{
              display: 'flex',
              marginTop: 25,
              justifyContent: 'flex-end',
            }}
          >
            <Dialog.Close asChild>
              <button
                onClick={handleAddAttempt}
                className='ProjectButton green'
              >
                Submit
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className='ProjectIconButton' aria-label='Close'>
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ProjectDialog;
