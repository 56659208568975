const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

export const sample = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

export const random = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;

export const getColumnByProjectTitle = (projectTitle, tasks) => {
  for (const columnId in tasks.columns) {
    if (tasks.columns[columnId].title === projectTitle) {
      return tasks.columns[columnId];
    }
  }
  return null;
};

export const updateOrder = async (project, taskSequence, token) => {
  const URL = AUTH_ENDPOINT + `task-sequence?project=${project}`;

  const HEADERS = {
    'Content-Type': 'application/json',
    Authorization: token,
  };
  const BODY = JSON.stringify(taskSequence);
  const request = new Request(URL, {
    method: 'PUT',
    headers: HEADERS,
    body: BODY,
    timeout: 100000,
  });
  fetch(request);
};
